@import 'dialog-polyfill/dialog-polyfill.css';
dialog {
  width: var(--user-message-width);
  border: 0;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 5px rgba(178, 179, 184, 0.5);
}
dialog header, dialog footer {
  height: auto;
  padding: 8px 16px;
  position: relative;
  background-color: transparent;
}
dialog header {
  justify-content: center;
}
dialog header h2 {
  color: var(--peppermint);
}
dialog footer {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
dialog footer button {
  background-color: white;
  color: black;
}
dialog article {
  padding: 0 16px;
}
dialog article section.contact-form {
  padding: 16px 0;
  border-top: 1px dotted var(--peppermint);
}
dialog article section.contact-form input {
  font-size: 1rem;
}
dialog article section.notes {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
dialog article section.notes label {
  font-size: 1rem;
  color: var(--blue);
}
dialog article section.notes textarea {
  font-family: "Lato", sans-serif;
  resize: vertical;
  flex-grow: 1;
  min-height: 6rem;
  border-width: 1px 1px 2px;
  border-style: solid;
  border-color: var(--blue);
  font-size: 1.1rem;
}
dialog article section.notes textarea:focus {
  outline: none;
}
dialog p {
  margin-top: 0;
  font-size: 1rem;
  line-height: 150%;
}