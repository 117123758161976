.contact-details {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 24px;

    .step-controls {
        justify-content: flex-end;
    }
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    label {
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 4px;

        &.required > small {
            color: var(--peppermint);
        }

        &.required + input {
            border-color: var(--peppermint);
        }
    }

    .name-wrapper {
        display: flex;
        align-items: center;

        .first-name, .last-name {
            display: flex;
            flex-direction: column;
            flex: 1 0;
        }

        .first-name {
            margin-right: 24px;
        }
    }
}

@media screen and (max-width: 480px) {
    .contact-form {
        .name-wrapper {
            flex-direction: column;
            align-items: stretch;

            .first-name {
                margin-right: 0;
            }
        }
    }
}