rm-user-message {
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: fixed;
    left: 0;
    right: 0;
    z-index: 25;
    transform: translateY(-100%);
    width: var(--user-message-width);

    margin: 0 auto;

    border-radius: 0 0 4px 4px;
    background-color: white;
    box-shadow: 0px 0px 10px 5px rgba(178,179,184,0.5);

    &.drop-out {
        animation: drop-out 0.25s forwards;
    }
    
    &.drop-back {
        animation: drop-back 0.25s forwards;
    }

    button {
        position: absolute;
        right: 8px;
        top: 8px;

        color: var(--peppermint);
        padding: 4px 8px;
        background-color: white;
        cursor: pointer;
    }

    section {
        margin: 8px;
        h4 {
            color: var(--dark-grey);
            margin: 4px 0;

            &.warning {
                font-size: larger;
                color: var(--peppermint);
            }
        }
    }

    article {
        color: var(--dark-grey);
        margin: 0 8px 8px;
    }
}

@keyframes drop-out {
    0% { transform: translateY(calc(-100% + 64px)); }
    100% { transform: translateY(64px); }
}

@keyframes drop-back {
    0% { transform: translateY(64px); }
    100% { transform: translateY(calc(-100% + 64px)); }
}