rm-drawing-control {
    margin: 10px;
    user-select: none;
    
    ul {
        padding: 0;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        border-radius: 4px;
        list-style: none;
        display: none;

        &.active {
            display: block;
        }
        
        li {
            display: table-cell;
            cursor: pointer;
            margin: 0;
            padding: 8px 12px;
            border-top: 4px solid white;
            border-right: 1px solid var(--teal);

            &:last-child {
                border-right: 1px solid transparent;
            }

            &.active {
                border-top: 4px solid var(--peppermint);

                svg {
                    fill: var(--peppermint);
                }
            }

            svg {
                fill: var(--blue);
                width: 24px;
                height: 24px;
            }
        }
    }
}