rm-caps-control {
    display: block;
    margin: 16px;

    h4 {
        button {
            margin: 0;
            padding: 0;
            border: none;
            background-color: transparent;
            color: var(--peppermint);
            font-weight: 600;
            cursor: pointer;
            text-decoration: underline;

            &:focus {
                outline: none;
            }
        }
    }

    label {
        font-weight: bold;
        color: var(--blue);
    }

    section {
        display: flex;
        flex-direction: column;

        input {
            flex-grow: 1;
            padding-left: 8px;
            margin-left: 0;
            background-color: white;
        }

        ul {
            margin: 8px 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 0;
                color: var(--blue);
                font-weight: 600;

                &.connector {
                    flex-grow: 1;
                    height: 1px;
                    margin: 0 8px;
                    border-top: 1px dotted var(--peppermint);
                }
            }
        }
    }

    small {
        color: var(--blue);
        font-weight: 500;

        &.dimmed {
            color: var(--peppermint);
        }
    }
}