.map-builder {
    position: fixed;
    display: block;
    width: 100vw;
    height: calc(100vh - 128px);

    .map {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }

    .tab-open {
        position: absolute;
    }

    .tab-open, .tab-close {
        z-index: 10;
        position: absolute;
        right: 28px;
        top: 16px;
        width: 24px;
        height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        button {
            color: black;
            background-color: white;
            border: solid 1px var(--peppermint);
            cursor: pointer;

            &:hover {
                background-color: var(--peppermint-50);
            }

            &:focus {
                outline: none;
            }
        }
    }

    .tab-open {
        left: 28px;
        top: 16px;
        z-index: 5;
    }
}