rm-location-finder {
    display: block;
    margin: 16px;

    section {
        display: flex;
        flex-direction: column;

        label {
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 4px;
        }
        
        input {
            flex-grow: 1;
            padding-left: 8px;
            background-color: white;
        }

        small.required {
            color: var(--peppermint);
        }
    }
}