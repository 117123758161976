rm-quote-panel {
    --shadow-def: 5px 0px 5px 0px rgba(178,179,184,0.5);

    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: var(--quote-panel-width);
    overflow-y: auto;

    background-color: white;
    box-shadow: var(--shadow-def);

    &.slide-in {
        animation: slide-in 0.25s forwards;
    }
    
    &.slide-out {
        animation: slide-out 0.25s forwards;
    }

    h4 {
        font-size: larger;
        border-left: 2px solid var(--peppermint);
        padding-left: 4px;
    }
    
    .helper {
        margin: 0 16px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            margin: 8px 0;
        }

        button {
            width: 10rem;
            display: block;
            background-color: white;
            color: black;
            border: solid 1px var(--peppermint);

            &:hover {
                background-color: var(--peppermint-50);
            }

        }
    }

    .quote-control {
        border-top: 1px dotted var(--peppermint);
        margin: 16px;
        display: flex;
        justify-content: center;
        
        button {
            margin: 16px 0;
        }
    }
}

@keyframes slide-in {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0%); }
}

@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}