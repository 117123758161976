rm-quote-summary {
    display: block;
    margin: 16px;

    section {
        display: flex;
        margin-bottom: 8px;

        span {
            flex-grow: 0;
            &.underline {
                flex-grow: 1;
                margin: 0 8px;
                border-bottom: 1px dotted var(--peppermint);
            }
        }
        
    }
}