rm-user-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 25;
  transform: translateY(-100%);
  width: var(--user-message-width);
  margin: 0 auto;
  border-radius: 0 0 4px 4px;
  background-color: white;
  box-shadow: 0px 0px 10px 5px rgba(178, 179, 184, 0.5);
}
rm-user-message.drop-out {
  animation: drop-out 0.25s forwards;
}
rm-user-message.drop-back {
  animation: drop-back 0.25s forwards;
}
rm-user-message button {
  position: absolute;
  right: 8px;
  top: 8px;
  color: var(--peppermint);
  padding: 4px 8px;
  background-color: white;
  cursor: pointer;
}
rm-user-message section {
  margin: 8px;
}
rm-user-message section h4 {
  color: var(--dark-grey);
  margin: 4px 0;
}
rm-user-message section h4.warning {
  font-size: larger;
  color: var(--peppermint);
}
rm-user-message article {
  color: var(--dark-grey);
  margin: 0 8px 8px;
}

@keyframes drop-out {
  0% {
    transform: translateY(calc(-100% + 64px));
  }
  100% {
    transform: translateY(64px);
  }
}
@keyframes drop-back {
  0% {
    transform: translateY(64px);
  }
  100% {
    transform: translateY(calc(-100% + 64px));
  }
}
.contact-details {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 24px;
}
.contact-details .step-controls {
  justify-content: flex-end;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.contact-form label {
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 4px;
}
.contact-form label.required > small {
  color: var(--peppermint);
}
.contact-form label.required + input {
  border-color: var(--peppermint);
}
.contact-form .name-wrapper {
  display: flex;
  align-items: center;
}
.contact-form .name-wrapper .first-name, .contact-form .name-wrapper .last-name {
  display: flex;
  flex-direction: column;
  flex: 1 0;
}
.contact-form .name-wrapper .first-name {
  margin-right: 24px;
}

@media screen and (max-width: 480px) {
  .contact-form .name-wrapper {
    flex-direction: column;
    align-items: stretch;
  }
  .contact-form .name-wrapper .first-name {
    margin-right: 0;
  }
}
rm-location-finder {
  display: block;
  margin: 16px;
}
rm-location-finder section {
  display: flex;
  flex-direction: column;
}
rm-location-finder section label {
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 4px;
}
rm-location-finder section input {
  flex-grow: 1;
  padding-left: 8px;
  background-color: white;
}
rm-location-finder section small.required {
  color: var(--peppermint);
}
rm-caps-control {
  display: block;
  margin: 16px;
}
rm-caps-control h4 button {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--peppermint);
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
rm-caps-control h4 button:focus {
  outline: none;
}
rm-caps-control label {
  font-weight: bold;
  color: var(--blue);
}
rm-caps-control section {
  display: flex;
  flex-direction: column;
}
rm-caps-control section input {
  flex-grow: 1;
  padding-left: 8px;
  margin-left: 0;
  background-color: white;
}
rm-caps-control section ul {
  margin: 8px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
rm-caps-control section ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  color: var(--blue);
  font-weight: 600;
}
rm-caps-control section ul li.connector {
  flex-grow: 1;
  height: 1px;
  margin: 0 8px;
  border-top: 1px dotted var(--peppermint);
}
rm-caps-control small {
  color: var(--blue);
  font-weight: 500;
}
rm-caps-control small.dimmed {
  color: var(--peppermint);
}
rm-quote-summary {
  display: block;
  margin: 16px;
}
rm-quote-summary section {
  display: flex;
  margin-bottom: 8px;
}
rm-quote-summary section span {
  flex-grow: 0;
}
rm-quote-summary section span.underline {
  flex-grow: 1;
  margin: 0 8px;
  border-bottom: 1px dotted var(--peppermint);
}
rm-quote-panel {
  --shadow-def: 5px 0px 5px 0px rgba(178,179,184,0.5);
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: var(--quote-panel-width);
  overflow-y: auto;
  background-color: white;
  box-shadow: var(--shadow-def);
}
rm-quote-panel.slide-in {
  animation: slide-in 0.25s forwards;
}
rm-quote-panel.slide-out {
  animation: slide-out 0.25s forwards;
}
rm-quote-panel h4 {
  font-size: larger;
  border-left: 2px solid var(--peppermint);
  padding-left: 4px;
}
rm-quote-panel .helper {
  margin: 0 16px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
rm-quote-panel .helper h4 {
  margin: 8px 0;
}
rm-quote-panel .helper button {
  width: 10rem;
  display: block;
  background-color: white;
  color: black;
  border: solid 1px var(--peppermint);
}
rm-quote-panel .helper button:hover {
  background-color: var(--peppermint-50);
}
rm-quote-panel .quote-control {
  border-top: 1px dotted var(--peppermint);
  margin: 16px;
  display: flex;
  justify-content: center;
}
rm-quote-panel .quote-control button {
  margin: 16px 0;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
rm-drawing-control {
  margin: 10px;
  user-select: none;
}
rm-drawing-control ul {
  padding: 0;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 4px;
  list-style: none;
  display: none;
}
rm-drawing-control ul.active {
  display: block;
}
rm-drawing-control ul li {
  display: table-cell;
  cursor: pointer;
  margin: 0;
  padding: 8px 12px;
  border-top: 4px solid white;
  border-right: 1px solid var(--teal);
}
rm-drawing-control ul li:last-child {
  border-right: 1px solid transparent;
}
rm-drawing-control ul li.active {
  border-top: 4px solid var(--peppermint);
}
rm-drawing-control ul li.active svg {
  fill: var(--peppermint);
}
rm-drawing-control ul li svg {
  fill: var(--blue);
  width: 24px;
  height: 24px;
}
.map-builder {
  position: fixed;
  display: block;
  width: 100vw;
  height: calc(100vh - 128px);
}
.map-builder .map {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.map-builder .tab-open {
  position: absolute;
}
.map-builder .tab-open, .map-builder .tab-close {
  z-index: 10;
  position: absolute;
  right: 28px;
  top: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-builder .tab-open button, .map-builder .tab-close button {
  color: black;
  background-color: white;
  border: solid 1px var(--peppermint);
  cursor: pointer;
}
.map-builder .tab-open button:hover, .map-builder .tab-close button:hover {
  background-color: var(--peppermint-50);
}
.map-builder .tab-open button:focus, .map-builder .tab-close button:focus {
  outline: none;
}
.map-builder .tab-open {
  left: 28px;
  top: 16px;
  z-index: 5;
}
dialog {
  position: absolute;
  left: 0; right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background: rgba(0,0,0,0.1);
}

._dialog_overlay {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}
dialog {
  width: var(--user-message-width);
  border: 0;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 5px rgba(178, 179, 184, 0.5);
}
dialog header, dialog footer {
  height: auto;
  padding: 8px 16px;
  position: relative;
  background-color: transparent;
}
dialog header {
  justify-content: center;
}
dialog header h2 {
  color: var(--peppermint);
}
dialog footer {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
dialog footer button {
  background-color: white;
  color: black;
}
dialog article {
  padding: 0 16px;
}
dialog article section.contact-form {
  padding: 16px 0;
  border-top: 1px dotted var(--peppermint);
}
dialog article section.contact-form input {
  font-size: 1rem;
}
dialog article section.notes {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
dialog article section.notes label {
  font-size: 1rem;
  color: var(--blue);
}
dialog article section.notes textarea {
  font-family: "Lato", sans-serif;
  resize: vertical;
  flex-grow: 1;
  min-height: 6rem;
  border-width: 1px 1px 2px;
  border-style: solid;
  border-color: var(--blue);
  font-size: 1.1rem;
}
dialog article section.notes textarea:focus {
  outline: none;
}
dialog p {
  margin-top: 0;
  font-size: 1rem;
  line-height: 150%;
}
/*# sourceMappingURL=app.800eca8f.css.map */
